import { ref } from '@vue/composition-api'
import store from '@/store'
import inventoryStoreModule from './inventoryStoreModule'
if (!store.hasModule('inventory')) store.registerModule('inventory', inventoryStoreModule)

export default function useInventoryList() {
  const loading = ref(false)

  return {
    loading
  }
}
