import { render, staticRenderFns } from "./PurchaseView.vue?vue&type=template&id=13cf7592&"
import script from "./PurchaseView.vue?vue&type=script&lang=js&"
export * from "./PurchaseView.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTabs,QTab,QTabPanels,QTabPanel,QChip,QScrollArea,QInnerLoading,QSpinnerGears});
