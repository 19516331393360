<template>
  <component :is="isDialog?'q-dialog':'div'" id="inventory-list" v-model="isDialogOpen" @hide="cancel">
    <div>
      <ListTable ref="tableRef" slim :columns="columns" :fetchAsync="fetchInventories" dataName="inventories" :icon="['fad', 'shopping-cart']" :selectable="selectable" search="names.name,orderNo">
        <!-- Index -->
        <template #cell(index)="{ item }"><span class="text-primary">{{item.index}}</span></template>
        <!-- name -->
        <template #cell(names)="{ item }">
          <div class="flex flex-nowrap align-items-center gap-2">
            <Avatar :url="item.files" />
            <div class="text-left">
              <div>{{item.names | name}}</div>
              <q-chip v-if="item.sku" size="sm">{{item.sku}}</q-chip>
            </div>
          </div>
        </template>
        <!-- Order number -->
        <template #cell(orderNo)="{ item }">
          <q-btn flat padding="0.25rem 0.50rem"  @click.stop="openOrderSidebar(item.orderName, item.orderId).then(v=>v?tableRef.refetchTable():'')" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <div class="flex gap-2 align-items-center flex-nowrap">
                <q-avatar size="sm" v-if="item.orderName" :color="`light-${item.orderName==='Purchase'?'success':(item.orderName==='Sale'?'danger':'')}`">
                  <fa-icon fixed-width :icon="['fas',$t('supply-chain.'+item.orderName.toLowerCase()+'.icon')]" />
                </q-avatar>
                <span v-if="item.orderNo">{{ item.orderNo.toUpperCase() }}</span>
              </div>
              <fa-icon :icon="['far','chevron-right']" class="text-primary ml-1"/>
            </div>
          </q-btn>
        </template>
        <!-- status -->
        <template #cell(status)="{ item }">
          <div v-if="item.status" class="text-left">
            <q-chip dense :color="'light-primary'" class="flex-nowrap">
              <q-avatar :color="$t(`supply-chain.status.${item.status}.color`)" text-color="white">
                <fa-icon :icon="['far',$t(`supply-chain.status.${item.status}.icon`)]" class="text-white"/>
              </q-avatar>
              <span class="ml-25 text-capitalize">{{$t(`supply-chain.status.${item.status}._`)}}</span>
            </q-chip>
          </div>
        </template>
        <!-- Quantity -->
        <template #cell(quantity.change.out)="{ item }">
          <div class="d-flex gap-1">
            <q-btn-group rounded class="w-100">
              <q-btn :color="item.quantity.change.in>0?'light-success':item.quantity.change.in<0?'light-danger':''" text-color="black" class="flex-even">
                <fa-icon :icon="['far','inbox-in']" class="mr-50"/>
                <span class="text-nowrap">{{item.quantity.change.in>0?'+':item.quantity.change.in<0?'-':''}} {{Math.abs(item.quantity.change.in)||0}}</span>
              </q-btn>
              <q-btn :color="item.quantity.change.onhand>0?'light-success':item.quantity.change.onhand<0?'light-danger':''" text-color="black" class="flex-even">
                <fa-icon :icon="['far','warehouse']" class="mr-50"/>
                <span class="text-nowrap">{{item.quantity.change.onhand>0?'+':item.quantity.change.onhand<0?'-':''}} {{Math.abs(item.quantity.change.onhand)||0}}</span>
              </q-btn>
              <q-btn :color="item.quantity.change.out>0?'light-success':item.quantity.change.out<0?'light-danger':''" text-color="black" class="flex-even">
                <fa-icon :icon="['far','inbox-out']" class="mr-50"/>
                <span class="text-nowrap">{{item.quantity.change.out>0?'+':item.quantity.change.out<0?'-':''}} {{Math.abs(item.quantity.change.out)||0}}</span>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
        <!-- Stock level -->
        <template #cell(quantity.after.out)="{ item }">
          <div class="d-flex gap-1">
            <q-btn-group rounded class="w-100">
              <q-btn color="" text-color="black" class="flex-even">
                <fa-icon :icon="['far','inbox-in']" class="mr-50"/>
                <span>{{item.quantity.after.in||0}}</span>
              </q-btn>
              <q-btn color="light-primary" text-color="black" class="flex-even">
                <fa-icon :icon="['far','warehouse']" class="mr-50"/>
                <span>{{item.quantity.after.onhand||0}}</span>
              </q-btn>
              <q-btn text-color="black" class="flex-even">
                <fa-icon :icon="['far','inbox-out']" class="mr-50"/>
                <span>{{item.quantity.after.out||0}}</span>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
        <!-- Amount -->
        <template #cell(total)="{ item }">
          <div class="text-nowrap" :class="item.total<0?'text-danger':'text-primary'">
            {{item.total||0 | price($t(`currency.${item.currency}.sign`))}}
          </div>
        </template>
        <!-- Items -->
        <template #cell(items.name)="{ item }">
          <div v-for="item in item.items.filter(t=>!t.isPayment)" :key="item._id" class="d-flex gap-1 justify-content-between">
            <div class="d-flex gap-1 align-items-center">
              <q-chip dense :color="item.isInventory?'light-primary':'light-danger'" class="flex-nowrap">
                <q-avatar v-if="item.isInventory" :color="item.isFulfilled?'success':'warning'" text-color="white">
                  <fa-icon :icon="item.isFulfilled?'check':'shipping-fast'" />
                </q-avatar>
                <q-avatar v-else color="danger" text-color="white">
                  <fa-icon icon="coins" />
                </q-avatar>
                <span class="ml-25 text-capitalize">{{item.name}}</span>
              </q-chip>
            </div>
            <div class="text-nowrap text-right d-flex align-items-center" :class="item.amount<0?'text-danger':'text-primary'">
              {{item.amount<0?'-':''}} {{item.currency}} {{Math.abs(item.amount||0) | price}}
            </div>
          </div>
        </template>
        <!-- CreatedAt -->
        <template #cell(createdAt)="{ item }">
          <div class="text-left">{{item.createdAt | date}}</div>
        </template>
      </ListTable>
    </div>
    <PurchaseView ref="purchaseViewRef"/>
    <SaleView ref="saleViewRef"/>
  </component>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { price, date, time, mmdd, name, i18n } from '@core/utils/filter'
import store from '@/store'
import useInventory from './useInventory'
import FileGallery from '@core/components/file-gallery/FileGallery'
import Avatar from '@core/components/Avatar'
import ListTable from '@core/components/ListTable'
import PurchaseView from '@/views/supply-chain/purchase/PurchaseView'
import SaleView from '@/views/supply-chain/sale/SaleView'

export default {
  name: 'inventory-list',
  components: {
    Avatar, FileGallery, ListTable,
    PurchaseView,
    SaleView
  },
  props:{
    isDialog: Boolean,
    showIcon: Boolean,
    selectable: Boolean,
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters: {
    price, date, time, mmdd, name
  },
  setup(props) {
    // Ref
    const purchaseViewRef = ref(null)
    const saleViewRef = ref(null)

    // Table
    const tableRef = ref(null)
    const columns = ref([
      { key: 'names', label:i18n('form.name'), sortable: true, noFilter: true},
      { key: 'orderNo', label:i18n('form.order_number'), sortable: true },
      { key: 'quantity.change.out', label:i18n('form.quantity'), isNumber:true, sortable: true},
      { key: 'quantity.after.out', label:i18n('supply-chain.inventory.stock_level'), isNumber:true, sortable: true},
      { key: 'total', label:i18n('form.total'), isNumber:true, sortable: true},
      { key: 'createdAt', label:i18n('form.date'), isDateRange: true, sortable: true },
    ])

    // Action
    const fetchInventories = params => store.dispatch('inventory/fetchInventories', params)

    // Dialog view
    const isDialogOpen = ref(false)
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    const openOrderSidebar = (order, id)=>{
      if (order==='Purchase') return purchaseViewRef.value.open(id)
      if (order==='Sale') return saleViewRef.value.open(id)
    }

    return {
      // Refs
      purchaseViewRef,
      saleViewRef,

      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,
      
      // Table
      columns,
      tableRef,

      // Action
      fetchInventories,
      openOrderSidebar
    }
  }
}
</script>