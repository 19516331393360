var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.isDialog?'q-dialog':'div',{tag:"component",attrs:{"id":"inventory-list"},on:{"hide":_vm.cancel},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('div',[_c('ListTable',{ref:"tableRef",attrs:{"slim":"","columns":_vm.columns,"fetchAsync":_vm.fetchInventories,"dataName":"inventories","icon":['fad', 'shopping-cart'],"selectable":_vm.selectable,"search":"names.name,orderNo"},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(item.index))])]}},{key:"cell(names)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex flex-nowrap align-items-center gap-2"},[_c('Avatar',{attrs:{"url":item.files}}),_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm._f("name")(item.names)))]),(item.sku)?_c('q-chip',{attrs:{"size":"sm"}},[_vm._v(_vm._s(item.sku))]):_vm._e()],1)],1)]}},{key:"cell(orderNo)",fn:function(ref){
var item = ref.item;
return [_c('q-btn',{staticClass:"w-100 rounded-lg",attrs:{"flat":"","padding":"0.25rem 0.50rem"},on:{"click":function($event){$event.stopPropagation();_vm.openOrderSidebar(item.orderName, item.orderId).then(function (v){ return v?_vm.tableRef.refetchTable():''; })}}},[_c('div',{staticClass:"flex-grow-1 d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"flex gap-2 align-items-center flex-nowrap"},[(item.orderName)?_c('q-avatar',{attrs:{"size":"sm","color":("light-" + (item.orderName==='Purchase'?'success':(item.orderName==='Sale'?'danger':'')))}},[_c('fa-icon',{attrs:{"fixed-width":"","icon":['fas',_vm.$t('supply-chain.'+item.orderName.toLowerCase()+'.icon')]}})],1):_vm._e(),(item.orderNo)?_c('span',[_vm._v(_vm._s(item.orderNo.toUpperCase()))]):_vm._e()],1),_c('fa-icon',{staticClass:"text-primary ml-1",attrs:{"icon":['far','chevron-right']}})],1)])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('div',{staticClass:"text-left"},[_c('q-chip',{staticClass:"flex-nowrap",attrs:{"dense":"","color":'light-primary'}},[_c('q-avatar',{attrs:{"color":_vm.$t(("supply-chain.status." + (item.status) + ".color")),"text-color":"white"}},[_c('fa-icon',{staticClass:"text-white",attrs:{"icon":['far',_vm.$t(("supply-chain.status." + (item.status) + ".icon"))]}})],1),_c('span',{staticClass:"ml-25 text-capitalize"},[_vm._v(_vm._s(_vm.$t(("supply-chain.status." + (item.status) + "._"))))])],1)],1):_vm._e()]}},{key:"cell(quantity.change.out)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex gap-1"},[_c('q-btn-group',{staticClass:"w-100",attrs:{"rounded":""}},[_c('q-btn',{staticClass:"flex-even",attrs:{"color":item.quantity.change.in>0?'light-success':item.quantity.change.in<0?'light-danger':'',"text-color":"black"}},[_c('fa-icon',{staticClass:"mr-50",attrs:{"icon":['far','inbox-in']}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.quantity.change.in>0?'+':item.quantity.change.in<0?'-':'')+" "+_vm._s(Math.abs(item.quantity.change.in)||0))])],1),_c('q-btn',{staticClass:"flex-even",attrs:{"color":item.quantity.change.onhand>0?'light-success':item.quantity.change.onhand<0?'light-danger':'',"text-color":"black"}},[_c('fa-icon',{staticClass:"mr-50",attrs:{"icon":['far','warehouse']}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.quantity.change.onhand>0?'+':item.quantity.change.onhand<0?'-':'')+" "+_vm._s(Math.abs(item.quantity.change.onhand)||0))])],1),_c('q-btn',{staticClass:"flex-even",attrs:{"color":item.quantity.change.out>0?'light-success':item.quantity.change.out<0?'light-danger':'',"text-color":"black"}},[_c('fa-icon',{staticClass:"mr-50",attrs:{"icon":['far','inbox-out']}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.quantity.change.out>0?'+':item.quantity.change.out<0?'-':'')+" "+_vm._s(Math.abs(item.quantity.change.out)||0))])],1)],1)],1)]}},{key:"cell(quantity.after.out)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex gap-1"},[_c('q-btn-group',{staticClass:"w-100",attrs:{"rounded":""}},[_c('q-btn',{staticClass:"flex-even",attrs:{"color":"","text-color":"black"}},[_c('fa-icon',{staticClass:"mr-50",attrs:{"icon":['far','inbox-in']}}),_c('span',[_vm._v(_vm._s(item.quantity.after.in||0))])],1),_c('q-btn',{staticClass:"flex-even",attrs:{"color":"light-primary","text-color":"black"}},[_c('fa-icon',{staticClass:"mr-50",attrs:{"icon":['far','warehouse']}}),_c('span',[_vm._v(_vm._s(item.quantity.after.onhand||0))])],1),_c('q-btn',{staticClass:"flex-even",attrs:{"text-color":"black"}},[_c('fa-icon',{staticClass:"mr-50",attrs:{"icon":['far','inbox-out']}}),_c('span',[_vm._v(_vm._s(item.quantity.after.out||0))])],1)],1)],1)]}},{key:"cell(total)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap",class:item.total<0?'text-danger':'text-primary'},[_vm._v(" "+_vm._s(_vm._f("price")(item.total||0,_vm.$t(("currency." + (item.currency) + ".sign"))))+" ")])]}},{key:"cell(items.name)",fn:function(ref){
var item = ref.item;
return _vm._l((item.items.filter(function (t){ return !t.isPayment; })),function(item){return _c('div',{key:item._id,staticClass:"d-flex gap-1 justify-content-between"},[_c('div',{staticClass:"d-flex gap-1 align-items-center"},[_c('q-chip',{staticClass:"flex-nowrap",attrs:{"dense":"","color":item.isInventory?'light-primary':'light-danger'}},[(item.isInventory)?_c('q-avatar',{attrs:{"color":item.isFulfilled?'success':'warning',"text-color":"white"}},[_c('fa-icon',{attrs:{"icon":item.isFulfilled?'check':'shipping-fast'}})],1):_c('q-avatar',{attrs:{"color":"danger","text-color":"white"}},[_c('fa-icon',{attrs:{"icon":"coins"}})],1),_c('span',{staticClass:"ml-25 text-capitalize"},[_vm._v(_vm._s(item.name))])],1)],1),_c('div',{staticClass:"text-nowrap text-right d-flex align-items-center",class:item.amount<0?'text-danger':'text-primary'},[_vm._v(" "+_vm._s(item.amount<0?'-':'')+" "+_vm._s(item.currency)+" "+_vm._s(_vm._f("price")(Math.abs(item.amount||0)))+" ")])])})}},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(_vm._f("date")(item.createdAt)))])]}}])})],1),_c('PurchaseView',{ref:"purchaseViewRef"}),_c('SaleView',{ref:"saleViewRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }