import { ref } from '@vue/composition-api'
import $global from '@/globalVariables'
import store from '@/store'
import { i18n } from '@core/utils/filter'
import purchaseStoreModule from './purchaseStoreModule'
if (!store.hasModule('purchase')) store.registerModule('purchase', purchaseStoreModule)
import { swal, Dialog } from '@core/utils/other'

export default function usePurchaseList(props, emit) {
  const loading = ref(false)

  // Purchase edit
  const emptyPurchase = ()=>({
    currency: $global.default_currency,
    orderedAt: (new Date).toLocaleDateString(undefined,{year: "numeric", month: "2-digit", day: "2-digit"}),
    variants: [],
    tags:[]
  })

  const purchaseData = ref(emptyPurchase())
  const resetPurchase = () => purchaseData.value = emptyPurchase()

  // 计算DR/CR平衡
  const total = ref({dr:0, cr:0})
  const balanceDifference = () => Math.abs(total.value.dr - total.value.cr)
  // watch(()=>purchaseData.value.variants, val => {
  //   total.value = {dr:0, cr:0} // reset
  //   if (!val || val.length===0) return
  //   val.filter(item=>!item.isJournaled).forEach(item => {
  //     if (item.drcr) total.value[item.drcr] += item.amount
  //   })
  //   total.value.dr = toNumber(total.value.dr)
  //   total.value.cr = toNumber(total.value.cr)
  // }, {deep: true})
  

  // Fetch a purchase 新增 / 修改
  const fetchPurchase = (id, callback, c)=>{
    if (!id) return
    return store.dispatch('purchase/fetchPurchase', id).then(r=>{
      purchaseData.value = r.purchase
      purchaseData.value.orderedAt = new Date(purchaseData.value.orderedAt).toLocaleDateString(undefined, {year: "numeric", month: "2-digit", day: "2-digit"})
      if(callback) callback(r.purchase)
    }).catch(e=>{
      resetPurchase()
      if(c) c()
    })
  }

// Approve
  const approvePurchase = async id => {
    const answer = await Dialog(true).fire({
      title: i18n('message.if_confirm_approve'),
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: '<i class="fas fa-check fa-2x fa-fw"/>',
      denyButtonText: '<i class="fas fa-ban fa-2x fa-fw"/>',
      cancelButtonText: '<i class="fas fa-undo-alt fa-2x fa-fw"/>'
    })
    let isApproved = null
    if(answer.isConfirmed) isApproved = true
    if(answer.isDenied) isApproved = false
    if(answer.isDismissed) return false

    return store.dispatch('purchase/approvePurchase', {id, isApproved}).then(() => true ).catch(e=>{
      Dialog(false).fire({ title: e.message })
      return false
    })
  }

// Delete
  const deletePurchase = (id) => Dialog(true, 'danger').fire({
    title: i18n('message.if_confirm_delete'),
    confirmButtonText: i18n('action.delete'),
  }).then(v => {
    return new Promise(res=>{
      if(!v.value) return res(false)
      store.dispatch('purchase/deletePurchase', id).then(()=>res(true)).catch(e=>{
        Dialog(false).fire({title: e.message})
        res(false)
      })
    })
  })

// Check in item
  const checkInItem = function(item){
    if(!item || !item._id) return
    swal.fire({
      icon:'question',
      showCancelButton: true,
      title: i18n('message.how_many'),
      input: 'range',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showLoaderOnConfirm: true,
      inputAttributes: {
        min: -item.fulfilledQuantity,
        max: item.balanceQuantity,
        step: 1
      },
      inputValue: item.balanceQuantity,
      preConfirm: (quantity) => {
        //validate
        if(quantity==0) return swal.showValidationMessage(i18n('rule.invalid_value'))

        return store.dispatch('purchase/checkInItem', {id:item._id, quantity})
        .then(() => {
          refetchPurchase(purchaseData.value._id)
        })
        .catch(error => {
          console.log(error);
          swal.showValidationMessage(`${i18n('rule.request_failed')}: ${error.message}`)
        })
      }
    })
  }

// Check in order
  const checkInOrder = function(id){
    if(!id) return
    swal.fire({
      icon:'question',
      showCancelButton: true,
      title: i18n('message.all_received'),
      showLoaderOnConfirm: true,
      preConfirm: () => store.dispatch('purchase/checkInOrder', id)
        .then(() => refetchData())
        .catch(error => swal.showValidationMessage(`${i18n('rule.request_failed')}: ${error.message}`))
    })
  }

  return {
    loading,
    total, //计算DR CR平衡
    balanceDifference,

    // Edit
    purchaseData,
    resetPurchase,
    fetchPurchase,
    approvePurchase,
    deletePurchase,

    // Check-in
    checkInItem,
    checkInOrder
  }
}
