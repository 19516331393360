<template>
  <component :is="isPage?'div':'BSidebar'" id="purchase-view" right sidebar-class="sidebar-lg" shadow backdrop  :width="sidebarWidth" header-class="p-0" bg-variant="white"
    :visible="isSidebarActive" @hidden="close()" class="rounded-lg overflow-hidden shadow-sm">
    <template v-slot:header>
      <div class="w-100">
        <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-white border-bottom shadow-sm z-index-10">
          <div v-if="purchaseData" class="flex gap-3 align-items-center text-primary">
            <fa-icon :icon="['fad', $t('supply-chain.purchase.icon')]" size="2x"/>
            <div class="flex flex-column">
              <span class="text-capitalize">{{$t('supply-chain.purchase._')}}</span>
              <span class="m-0 h5">{{purchaseData.orderNo}}</span>
            </div>
            <q-btn flat padding="5px" :to="{name: 'purchase-view', params:{purchaseId: purchaseData._id}}"><fa-icon icon="expand"/></q-btn>
          </div>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="close"/>
        </div>
        <q-tabs v-model="tab" class="bg-light-secondary inner-shadow" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
          <q-tab name="details" icon="fas fa-info-circle" :label="$t('form.details') | capitalize" class="pt-50 " content-class="gap-50 mb-25" />
          <q-tab name="variants" icon="fas fa-tshirt" :label="$t('form.variants') | capitalize" class="pt-50 " content-class="gap-50 mb-25" />
          <q-tab name="history" icon="fas fa-truck" :label="$t('form.history') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
        </q-tabs>
      </div>
    </template>

    <q-tabs v-if="isPage" v-model="tab" class="bg-light-secondary" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
      <q-tab name="details" icon="fas fa-info-circle" :label="$t('form.details') | capitalize" class="pt-50 " content-class="gap-50 mb-25" />
      <q-tab name="variants" icon="fas fa-tshirt" :label="$t('form.variants') | capitalize" class="pt-50 " content-class="gap-50 mb-25" />
      <q-tab name="history" icon="fas fa-truck" :label="$t('form.history') | capitalize" class="pt-50" content-class="gap-50 mb-25"/>
    </q-tabs>
    <q-tab-panels v-model="tab" value="order" animated transition-prev="jump-left" transition-next="jump-right" class="h-100 bg-white">
      <q-tab-panel name="details" :class="{'bg-light-danger':purchaseData.isActive===false}" class="p-0">
        <div v-if="purchaseData">
          <Carousel :files="purchaseData.files" />
          <div class="flex flex-column flex-sm-row flex-wrap px-2 pt-2 gap-3 shadow-top-sm">
            <!-- Order number -->
            <div class="flex-size-4 flex-even d-flex gap-2">
              <fa-icon :icon="['fad','shopping-cart']" fixed-width class="text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('form.order_number')}}</label>
                <div>{{purchaseData.orderNo}}</div>
              </div>
            </div>
            <!-- Tracking number -->
            <div class="flex-size-4 flex-even d-flex gap-2">
              <fa-icon :icon="['fad','shipping-fast']" fixed-width class="text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('form.track_number')}}</label>
                <span>{{purchaseData.trackNo}}</span>
              </div>
            </div>
            <!-- Date -->
            <div class="flex-size-4 flex-even d-flex flex-even gap-2">
              <fa-icon :icon="['fad','calendar-alt']" fixed-width class="text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('supply-chain.purchase.purchase_date')}}</label>
                <span class="text-wrap">{{purchaseData.orderedAt | date}}</span>
              </div>
            </div>
            <!-- Description -->
            <div class="flex-size-4 flex-even d-flex gap-2">
              <fa-icon :icon="['fad','align-left']" fixed-width class="text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('form.description')}}</label>
                <span class="text-wrap">{{purchaseData.description}}</span>
              </div>
            </div>
            <!-- Tags -->
            <div class="flex-even flex-size-4 flex gap-2">
              <fa-icon :icon="['fad','tags']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('form.tags')}}</label>
                <span class="d-flex flex-wrap gap-1">
                  <q-chip v-for="(tag, index) in purchaseData.tags" color="light-primary" :key="tag.index" dense >{{tag}}</q-chip>
                  <q-btn flat dense @click.stop="updateTags({data:purchaseData.tags, label:$t('form.tags'), icon: ['fad','tags']})"><fa-icon class="text-danger mx-n1" icon="pen"/></q-btn>
                </span>
              </div>
            </div>
          </div>

          <div class="m-2">
            <BTable ref="refPurchaseListTable" class="text-center rounded-lg shadow-sm mb-0" responsive show-empty hover primary-key="_id"
              :items="purchaseData.variants" :fields="[
                {key:'names',label:$t('form.name')},
                {key:'price',label:$t('form.price')},
                {key:'quantity',label:$t('form.quantity')},
                {key:'total',label:$t('form.total')}]">
              <!-- Name -->
              <template #cell(names)="{ item }">
                <div class="d-flex gap-1 align-items-center">
                  <Avatar :url="item.files" />
                  <q-chip size="sm" color="light-secondary" text-color="white" class="px-0">
                    <q-chip size="sm" :color="item.isInventory?'primary':'danger'" text-color="white">{{item.productNames | name}}</q-chip>
                    <span class="mx-50 h5 mb-0">{{item.names | name}}</span>
                  </q-chip>
                  <!-- Journal status -->
                  <q-chip dense v-if="item.isJournaled" color="light-success">
                    <fa-icon :icon="['far','check-double']" />
                  </q-chip>
                  <!-- Approve status -->
                  <q-chip dense v-else-if="item.isApproved" color="light-success">
                    <fa-icon :icon="['far', 'check']" />
                  </q-chip>
                  <q-chip dense v-if="item.isReceived" color="light-success">
                    <fa-icon :icon="['far','box-check']" />
                  </q-chip>
                </div>
              </template>
              <!-- Price -->
              <template #cell(price)="{ item }">
                <div class="text-nowrap d-flex justify-content-center align-items-center" :class="item.price<0?'text-danger':'text-primary'">
                  {{item.price | price($t(`currency.${item.currency}.sign`))}}
                </div>
              </template>
              <!-- Total -->
              <template #cell(total)="{ item }">
                <div class="text-nowrap d-flex justify-content-center align-items-center" :class="item.total<0?'text-danger':'text-primary'">
                  {{item.total | price($t(`currency.${item.currency}.sign`))}}
                </div>
              </template>
            </BTable>
          </div>
          
          <div v-if="purchaseData.purchase" class="shadow-sm rounded-lg m-2">
            <div class="card-header bg-light-primary p-1 h5">
              <fa-icon :icon="$t('member.purchase.icon')" class="mr-50" fixed-width/>
              {{$t('member.purchase._')}}
            </div>
            <div class="form px-1 py-2 d-flex gap-1 flex-wrap">
              <!-- 公司名 -->
              <div v-if="purchaseData.purchase.corpName" class="flex-size-4 flex-even d-flex gap-2">
                <fa-icon :icon="['fad','building']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <label class="text-primary d-block">{{$t('form.corp_name')|capitalize}}</label>
                  <span class="text-wrap">{{purchaseData.purchase.corpName}}</span>
                </div>
              </div>
              <!-- UEN -->
              <div v-if="purchaseData.purchase.uen" class="flex-size-4 flex-even d-flex gap-2">
                <fa-icon :icon="['fad','building']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <label class="text-primary d-block">{{$t('form.uen')|capitalize}}</label>
                  <span class="text-wrap">{{purchaseData.purchase.uen}}</span>
                </div>
              </div>
              <!-- 联系人 -->
              <div v-if="purchaseData.purchase.name" class="flex-size-4 flex-even d-flex gap-2">
                <fa-icon :icon="['fad','user']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <label class="text-primary d-block">{{$t('form.name')|capitalize}}</label>
                  <span class="text-wrap">{{purchaseData.purchase.name}}</span>
                </div>
              </div>
              <!-- 电话 -->
              <div v-if="purchaseData.purchase.phone" class="flex-size-4 flex-even d-flex gap-2">
                <fa-icon :icon="['fad','mobile-android-alt']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <label class="text-primary d-block">{{$t('form.phone')|capitalize}}</label>
                  <span class="text-wrap">+{{purchaseData.purchase.country}} {{purchaseData.purchase.phone}}</span>
                </div>
              </div>
              <!-- 邮箱 -->
              <div v-if="purchaseData.purchase.email" class="flex-size-4 flex-even d-flex gap-2">
                <fa-icon :icon="['fad','at']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <label class="text-primary d-block">{{$t('form.email')|capitalize}}</label>
                  <span class="text-wrap">{{purchaseData.purchase.email}}</span>
                </div>
              </div>
              <!-- 邮编 & City -->
              <div v-if="purchaseData.purchase.zip" class="flex-size-4 flex-even d-flex gap-2">
                <fa-icon :icon="['fad','mailbox']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <label class="text-primary d-block">{{$t('form.zip')|capitalize}}</label>
                  <span class="text-wrap">{{purchaseData.purchase.zip}}</span>
                </div>
              </div>
              <!-- 地址 -->
              <div v-if="purchaseData.purchase.address" class="flex-size-4 flex-even d-flex gap-2">
                <fa-icon :icon="['fad','map-marker-alt']" fixed-width class="text-primary text-size-150"/>
                <div>
                  <label class="text-primary d-block">{{$t('action.address')|capitalize}}</label>
                  <span class="text-wrap">{{purchaseData.purchase.address}}</span>
                </div>
              </div>
            </div>
          </div>

          <AppCollapse v-if="purchaseData.entries && purchaseData.entries.length>0" class="shadow-sm m-2">
            <AppCollapseItem class="shadow-none" title nobody :visible.sync="visible" headerClass="bg-light-primary">
              <!-- Header -->
              <template #header>
                <span class="h5 m-0 text-primary text-capitalize">
                  <fa-icon :icon="['fas', 'dollar-sign']" class="mr-50" fixed-width />
                  {{$t('finance.transaction.transactions')}}
                </span>
              </template>

              <template #show>
                <div v-if="visible && purchaseData.entries && purchaseData.entries.length>0" class="d-flex flex-column gap-2 p-1">
                  <AccountSidebarEntry v-for="entry in purchaseData.entries" :key="entry._id" :entryId="entry._id" />
                </div>
              </template>
            </AppCollapseItem>
          </AppCollapse>

          <Logs model="purchase" :data="purchaseData.logs" :modelId="purchaseData._id" :quantity="2" :addable="!purchaseData.deleted" :title="$t('log._')" class="m-2"/>
        </div>
      </q-tab-panel>

      <!-- Variants -->
      <q-tab-panel name="variants" :class="{'bg-light-danger':purchaseData.isActive===false}" class="p-0">
        <component :is="isPage?'div':'q-scroll-area'" class="h-100 overflow-hidden" delay='600' :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.6 }"
          :bar-style="{right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', background: 'dark', opacity: 0.2}">
          <BTable class="position-relative shadow mb-0 text-center bg-white" responsive show-empty hover primary-key="_id"
            :items="purchaseData.variants" :fields="[
              {key:'names',label:$t('form.name')}, 
              {key:'quantity',label:$t('form.quantity')}, 
              {key:'price',label:$t('form.price')},
              {key:'total',label:$t('form.total')},
              {key:'isReceived',label:$t('supply-chain.status.received._')}]">
            <!-- Name -->
            <template #cell(names)="{ item }">
              <div class="d-flex gap-2 align-items-center">
                <Avatar :url="item.files" />
                <q-chip size="sm" color="light-secondary" text-color="white" class="px-0">
                  <q-chip size="sm" :color="item.isInventory?'primary':'danger'" text-color="white">{{item.productNames | name}}</q-chip>
                  <span class="mx-50 h5 mb-0">{{item.names | name}}</span>
                </q-chip>
              </div>
            </template>
            <!-- Price -->
            <template #cell(price)="{ item }">
              <div class="text-nowrap d-flex justify-content-center align-items-center" :class="item.price<0?'text-danger':'text-primary'">
                {{item.price | price($t(`currency.${item.currency}.sign`))}}
              </div>
            </template>
            <!-- Total -->
            <template #cell(total)="{ item }">
              <div class="text-nowrap d-flex justify-content-center align-items-center" :class="item.total<0?'text-danger':'text-primary'">
                {{item.total | price($t(`currency.${item.currency}.sign`))}}
              </div>
            </template>
            <!-- Received -->
            <template #cell(isReceived)="{ item }">
              <q-btn push :color="item.isReceived?'success':'secondary'" @click.stop="receiveItem(item._id, !item.isReceived, item)" :loading="item.loading">
                <fa-icon :icon="item.isReceived?'check':'times'" class="my-25" fixed-width/>
              </q-btn>
            </template>
          </BTable>
        </component>
      </q-tab-panel>
    </q-tab-panels>
    <FieldDialog ref="fieldDialogRef" />
    <q-inner-loading :showing="loading">
      <q-spinner-gears size="50px" color="primary" />
    </q-inner-loading>
  </component>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { ref, watch, set } from '@vue/composition-api'
import { BSidebar, BTable } from 'bootstrap-vue'
import { date, capitalize, price, i18n, name } from '@core/utils/filter'
import { Toast, Dialog } from '@core/utils/other'
import usePurchase from './usePurchase'
import Carousel from '@core/components/Carousel'
import FileGallery from '@core/components/file-gallery/FileGallery'
import FieldDialog from '@core/components/FieldDialog'
import Logs from '@core/components/logs/Logs'
import AccountSidebarEntry from '@/views/finance/account/components/sidebar/AccountSidebarEntry'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Avatar from '@core/components/Avatar'
export default {
  name: 'purchase-view',
  components: {
    Carousel,
    BSidebar, BTable,
    Avatar, FileGallery,
    FieldDialog,
    Logs,
    AccountSidebarEntry,
    AppCollapse, AppCollapseItem,
  },
  props:{
    isPage: Boolean
  },
  filters: {
    date, capitalize, price, i18n, name
  },
  setup(props){
    const { route, router } = useRouter()
    const {
      loading,
      purchaseData,
      fetchPurchase,
      approvePurchase
    } = usePurchase()

    // Page view
    if (props.isPage) {
      if (route.value.params.purchaseId) {
        loading.value = true
        fetchPurchase(route.value.params.purchaseId, ()=>loading.value = false)
      } else {
        router.go(-1)
      }
    }

    // Sidebar view
    const isSidebarActive = ref(false)
    let isUpdated = false
    let closeAction = () => isSidebarActive.value = false
    const close = () => closeAction()
    const open = async id => {
      return new Promise(res=>{
        if(!id) return res(false)
        loading.value = true
        isSidebarActive.value = true
        fetchPurchase(id, ()=> {
          loading.value = false
          closeAction = () => {
            res(isUpdated)
            isSidebarActive.value = false
          }
        })
      })
    }

    // Tabs
    const tab = ref('details')
    const sidebarWidth = ref('800px')
    watch(tab, val=>{
      if (val==='details') sidebarWidth.value = "800px"
      if (val==='variants') sidebarWidth.value = "800px"
      if (val==='history') sidebarWidth.value = '800px'
    })

    // Update tags
    const fieldDialogRef = ref(null)
    const updateTags = async () =>{
      const newTags = await fieldDialogRef.value.openDialog({data:purchaseData.value.tags, label:i18n('form.tags'), icon: ['fad','tags']})
      if (newTags === false) return
      loading.value = true
      store.dispatch('purchase/updateTags', {purchaseId: purchaseData.value._id, tags:newTags}).then(()=>fetchPurchase(purchaseData.value._id, ()=>{
        isUpdated = true
        loading.value = false
      })).catch(e=>{
        loading.value = false
        Dialog(false).fire({
          icon: 'error',
          title: e.message
        })
      })
    }

    // Packing
    const receiveItem = (id, isReceived, itemObj)=>{
      set(itemObj, 'loading', true)
      store.dispatch('purchase/receiveItem', {id, isReceived}).then(response=>{
        itemObj.isReceived = response.isReceived
        itemObj.loading = false
      }).catch(e=>{
        Toast.fire({ icon: 'error', title: e.message })
        itemObj.loading = false
      })
    }

    return {
      loading,
      purchaseData,

      // Sidebar
      isSidebarActive,
      sidebarWidth,
      open,
      close,

      // Tab
      tab,

      // Actions
      approvePurchase,
      
      // Dialog
      fieldDialogRef,
      updateTags,
      
      receiveItem
    }
  }
}
</script>

<style lang="scss">
html {
  overflow: hidden;
}
#purchase-view{
  transition: all 300ms;
  //Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  .b-sidebar-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .text-size-150 {
    font-size: 1.5rem
  }
  .z-index-10 {
    z-index: 10
  }
  .form {
    span:empty:before {
      content: "\200b"; // unicode zero width space character
    }
  }
}
</style>